/* for pos button style */
.c-pos-button1,
.c-pos-button2,
.c-pos-button3,
.c-pos-button4 {
  cursor: pointer;
  overflow: hidden;
}

.c-pos-button1:hover {
  box-shadow: 0px 0px 5px 3px rgb(114, 0, 0) !important;
  transform: translate3d(0, 0, 5px);
}

.c-pos-button2:hover {
  box-shadow: 0px 0px 5px 3px rgb(1, 68, 112) !important;
  transform: translate3d(0, 0, 5px);
}

.c-pos-button3:hover {
  box-shadow: 0px 0px 5px 3px rgb(0, 43, 11) !important;
  transform: translate3d(0, 0, 5px);
}

.c-pos-button4:hover {
  box-shadow: 0px 0px 5px 3px rgb(26, 4, 141) !important;
  transform: translate3d(0, 0, 5px);

}

.c-pos-button5:hover {
  box-shadow: 0px 0px 5px 5px rgb(151, 149, 155) !important;
  transform: translate3d(0, 0, 5px);

}

.c-pos-button6:hover {
  box-shadow: 0px 0px 3px 3px #032c66 !important;
  transform: translate3d(0, 0, 5px);
}

.c-pos-button7:hover {
  box-shadow: 0px 0px 1px 1px #000000 !important;
  transform: translate3d(0, 0, 5px);
}

.c-pos-button8:hover{
  box-shadow: 0px 0px 5px 2px #000000!important; 
  transform: translate3d(0, 0, 5px);
}
/* cursor pointer */
.cs-cursor-pointer {
  cursor: pointer !important;
}

.cs-text-decoration-none {
  text-decoration: none !important;
}

.cs-text-decoration-underline {
  text-decoration: underline !important;
}

/* input styles*/
.cs-text-input {
  width: 100% !important;
  border-radius: .30rem !important;
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #000000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
}

.text-input:focus {
  box-shadow: 0px 0px 1px 2px rgb(43, 0, 255) !important;

}

/* text-hover */
.bg-green-hover {
  background-color: #19ac20 !important;
  color: rgb(255, 255, 255) !important;
}

.bg-blue-hover {
  background-color: #0c37af !important;
  color: rgb(255, 255, 255) !important;
}

.bg-orange-hover {
  background-color: #af320c !important;
  color: rgb(255, 255, 255) !important;
}

/* bg--hover */
.bg-black-hover:hover {
  background-color: #000000 !important;
  color: white !important;
}

.bg-white-hover:hover {
  background-color: #ffffff;
  color: black !important;
}

.bg-red-hover:hover {
  background-color: #ec0303 !important;
  color: white !important;
}

.bg-green-hover:hover {
  background-color: #0d6b12 !important;
  color: rgb(230, 230, 230) !important;
}

.bg-blue-hover:hover {
  background-color: #0d266b !important;
  color: rgb(240, 240, 240) !important;
}

.bg-orange-hover:hover {
  background-color: #802104 !important;
  color: rgb(255, 255, 255) !important;
}

.bg-gray-hover:hover {
  background-color: #6c757d;
}

.bg-black-hover:hover .color-white-hover {
  color: #ffffff;
}

.bg-gray-two-hover:hover {
  background-color: #e6e9ec;
}
.bg-gray-two-hover2:hover {
  background-color: #f7f5f6;
}

/* button */
.btn-primary {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: none !important;
}

.btn-primary:hover {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: none !important;
}

.btn-primary:focus {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: none !important;
}

/* button-add */
    .btn-add {
        color: #dddddd !important;
        background-color: #06580a !important;
        border: none !important;
    }
    .btn-add:hover {
        color: #ffffff !important;
        background-color: #0d6b12 !important;
        border: none !important;
    }
    .red-hover:hover{
      color: red !important;
    }


/* Add this CSS to your component's stylesheet or a global stylesheet */

/* Style the scrollbar track (the background) */
::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width as needed */
  background-color: #ffffff;
  /* Adjust the background color */

}

/* Style the scrollbar thumb (the handle) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Adjust the scrollbar thumb color */
  border-radius: 5px;
  /* Adjust the border-radius if needed */
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(9, 61, 134);
  /* Adjust the hover color */
}


/* input number increase value */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* font-size */
.fs-7 {
  font-size: 1.1rem !important;
}

tbody tr th {
  font-weight: 400;
}
/* pathao city */
.custom-dropdown {
  margin-bottom: 15px;
}

.custom-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-select:focus {
  background-color: #ffffff;
  color: #000000;
}
.custom-option:hover {
  color: #06580a;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  color: #ffffff !important;
  padding-left: 3px;
  transition: 0.3s;
}
.print-barcode {
  margin-top: 8px;
  margin-bottom: 8px;

}

@media print {
  .print-barcode {
    margin: 0;
  }
}


ul.sidebar-menu.pt-1 > li {
    padding-top: 10px;
    padding-bottom: 10px;
}

.profitnloss tbody tr td {
  padding: 10px 20px;
}
.card-bg-color {
  background-color: #ffffff;
}
.card-bg-color:hover {
  background-color: #000000;
}

.card-bg-color:hover .card-text-color {
  color: rgb(255, 255, 255);
}

.not-allowed     { cursor: not-allowed; }