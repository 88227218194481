 .card-left.with-bg{
    padding: 50px;
    background-color: black;
    background-image: url('../../assets/images/cat3.png');
    
    background-position: center;
    box-shadow: 1px 5px 24px 0 rgba(255, 128, 132, 0.8)
 }

 .wellcome-tt{
   font-size: 50px !important;
   margin-top: -45px !important;
   background: #082247;
   padding-top: 0;

 }
.bg-navy{
   background-color: #082247 !important;
}
.bg-cccc{
   background: url('https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80');
}

.css-selector {
    background: linear-gradient(270deg, #de72f4b9, #72f4d6de, #0000ffc7, #ff6600d2);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
